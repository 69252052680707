import { CexCountry } from '@/services/contentful';

import { CountryData, CountryDataProps } from '../country.utils';
import { SlugParams } from './currency-corridor.types';

export const getCurrencySlug = ({
  currencyCode,
}: {
  currencyCode?: string;
}) => {
  if (!currencyCode) return '';

  return `currency-converter/currencies/${currencyCode}`.toLowerCase();
};

export const getCurrencyCorridorSlug = ({
  sendCurrencyCode,
  receiveCurrencyCode,
  sendCountryCode,
  receiveCountryCode,
  cexPageSlug,
}: {
  sendCurrencyCode: string;
  receiveCurrencyCode: string;
  sendCountryCode?: string;
  receiveCountryCode?: string;
  cexPageSlug?: string;
}) => {
  if (!sendCurrencyCode || !receiveCurrencyCode) return cexPageSlug || '';
  return `currency-converter/${sendCurrencyCode}${
    sendCountryCode ? `_${sendCountryCode}` : ''
  }-${receiveCurrencyCode}${
    receiveCountryCode ? `_${receiveCountryCode}` : ''
  }`.toLowerCase();
};

export const getCorridorDetailsFromSlug = (slug: string): SlugParams => {
  const slugParts = slug.split('-');

  const slugParams = {
    sendCurrencyCode: '',
    receiveCurrencyCode: '',
    sendCountryCode: '',
    receiveCountryCode: '',
    calculationAmount: 0,
  };

  if (slugParts.length === 2) {
    slugParams.calculationAmount = 0;
    slugParams.sendCurrencyCode = slugParts[0].split('_')[0];
    slugParams.sendCountryCode = slugParts[0].split('_')[1];
    slugParams.receiveCurrencyCode = slugParts[1].split('_')[0];
    slugParams.receiveCountryCode = slugParts[1].split('_')[1];
  }

  if (slugParts.length === 3) {
    slugParams.calculationAmount = Number(slugParts[0]) || 0;
    slugParams.sendCurrencyCode = slugParts[1].split('_')[0];
    slugParams.sendCountryCode = slugParts[1].split('_')[1];
    slugParams.receiveCurrencyCode = slugParts[2].split('_')[0];
    slugParams.receiveCountryCode = slugParts[2].split('_')[1];
  }

  return slugParams;
};

export const filterSendCountries = (countries: CountryData[]) => {
  return countries
    .filter(country => country.send)
    .reduce((acc, sendCountry) => {
      const receiveCountries = sendCountry.validReceiveCountriesIso2
        ?.map(receiveIso2 =>
          countries.find(receiveCountry => receiveCountry.iso2 === receiveIso2),
        )
        .filter((country): country is CountryDataProps => Boolean(country));

      if (receiveCountries && receiveCountries.length > 0) {
        acc[sendCountry.iso2] = {
          ...sendCountry,
          receiveCountries,
        };
      }

      return acc;
    }, {} as Record<string, CountryData & { receiveCountries: CountryData[] }>);
};

export const filterReceiveCountries = (countries: CountryData[]) => {
  return countries
    .filter(country => country.receive)
    .reduce((acc, receiveCountry) => {
      const sendCountries = countries
        .filter(sendCountry =>
          sendCountry.validReceiveCountriesIso2?.includes(receiveCountry.iso2),
        )
        .map(sendCountry => ({
          ...sendCountry,
          receiveCountries: [receiveCountry],
        }));

      if (sendCountries && sendCountries.length > 0) {
        acc[receiveCountry.iso2] = {
          ...receiveCountry,
          sendCountries,
        };
      }

      return acc;
    }, {} as Record<string, CountryDataProps & { sendCountries: CountryDataProps[] }>);
};

export const generateCorridorResponse = (
  sendCountry: CountryDataProps,
  receiveCountry: CountryDataProps | undefined,
  sendCurrencyCode?: string,
  receiveCurrencyCode?: string,
) => {
  return {
    sendCurrency: {
      currencyCode: sendCountry.currency || '',
      currencyName: sendCountry.currencyDisplay || '',
      countryCode: sendCountry.iso2.toUpperCase(),
      countryName: sendCountry.name,
      pageSlug: `currency-converter/currencies/${sendCountry.currency}${
        sendCountry.iso2 ? `_${sendCountry.iso2}` : ''
      }`.toLowerCase(),
    },
    receiveCurrency: {
      currencyCode: receiveCountry?.currency || '',
      currencyName: receiveCountry?.currencyDisplay || '',
      countryCode: receiveCountry?.iso2.toUpperCase() || '',
      countryName: receiveCountry?.name || '',
      pageSlug: `currency-converter/currencies/${receiveCountry?.currency}${
        receiveCountry?.iso2 ? `_${receiveCountry.iso2}` : ''
      }`.toLowerCase(),
    },
    pageSlug:
      getCurrencyCorridorSlug({
        sendCurrencyCode: sendCurrencyCode?.toLowerCase() as string,
        receiveCurrencyCode: receiveCurrencyCode?.toLowerCase() as string,
      }) || '',
  };
};

export const adaptCountryToCexCountry = (
  country: CountryDataProps,
): CexCountry => {
  return {
    code: country.iso2,
    name: country.name,
    currency: country.currency
      ? {
          __typename: 'LocalizedCurrency',
          code: (country.currency as unknown) as string,
          name: country.currency,
        }
      : null,
  };
};
