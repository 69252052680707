import { useMemo } from 'react';

import { CountryData, useCountriesStore } from '@/state';

export const useSendAndReceiveCountries = (): Record<string, CountryData[]> => {
  const countries = useCountriesStore(
    state => state.countries,
  ) as CountryData[];

  const filteredSendCountries = useMemo(
    () => countries?.filter(country => country.send) ?? [],
    [countries],
  );

  const filteredReceiveCountries = useMemo(
    () => countries?.filter(country => country.receive) ?? [],
    [countries],
  );

  return {
    sendCountries: filteredSendCountries,
    receiveCountries: filteredReceiveCountries,
  };
};
