import { DEFAULT_LOCALE } from '@wr/web-shared';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useAppContext } from '@/context';
import { getIntlMessages, GetIntlMessagesResult } from '@/utils/intl/messages';

import { useSendAmount, useSendAndReceiveCountries } from '.';

export function useIntlMessages():
  | GetIntlMessagesResult
  | Record<string, never> {
  const router = useRouter();
  const { locale = DEFAULT_LOCALE } = router;
  const { sendCountry, receiveCountry, intl } = useAppContext();
  const { sendCountries, receiveCountries } = useSendAndReceiveCountries();

  const { values, extractSendAmountFromUrl } = useSendAmount();

  return useMemo(() => {
    if (!sendCountry || !receiveCountry) {
      return {};
    }

    const sendAmount =
      extractSendAmountFromUrl()?.toString() || values.SEND.toString();

    const currentDate = new Date();

    const result = {
      ...getIntlMessages({
        sendCountry,
        sendCountries,
        receiveCountry,
        payoutMethodsLabels: intl.payoutMethodsLabelsById,
        countryArticlesAndPrepositions: intl.countryArticlesAndPrepositions,
        currentDate,
        locale,
        receiveCountries,
        corridorPayoutMethodsIds: [],
        sendAmount,
      }),
    };

    return result;
  }, [
    extractSendAmountFromUrl,
    values.SEND,
    sendCountry,
    receiveCountry,
    sendCountries,
    receiveCountries,
    intl.payoutMethodsLabelsById,
    intl.countryArticlesAndPrepositions,
    locale,
  ]);
}
